<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.5524 22.1325C15.1114 22.0795 11.0307 21.5161 6.75735 17.2427C2.48396 12.9693 1.92057 8.88862 1.86754 7.44767C1.78675 5.25221 3.46855 3.11902 5.41215 2.28605C5.86822 2.09059 6.4206 2.12636 6.85728 2.44489C8.45608 3.61111 9.56072 5.37435 10.509 6.76166C10.9374 7.38835 10.8351 8.244 10.2531 8.74772L8.30198 10.1967C8.10859 10.3404 8.04429 10.6014 8.16028 10.8125C8.60173 11.6161 9.38819 12.8119 10.2882 13.7119C11.1891 14.6128 12.4414 15.45 13.3002 15.9412C13.5229 16.0685 13.803 15.9948 13.9438 15.7803L15.2131 13.8468C15.6999 13.1991 16.6088 13.0576 17.2695 13.5149C18.677 14.489 20.3188 15.5737 21.521 17.1133C21.8626 17.5507 21.9133 18.1227 21.7096 18.5981C20.8728 20.5507 18.7552 22.2136 16.5524 22.1325Z"
			fill="#1347FF"
		/>
	</svg>
</template>
