<template>
	<svg
		width="23"
		height="23"
		viewBox="0 0 23 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_188_5336)">
			<path
				d="M15.9082 0H6.5918C2.95312 0 0 2.95312 0 6.5918V15.9082C0 19.5469 2.95312 22.5 6.5918 22.5H15.9082C19.5469 22.5 22.5 19.5469 22.5 15.9082V6.5918C22.5 2.95312 19.5469 0 15.9082 0ZM4.21875 11.9971V10.5029C4.21875 10.1391 4.51406 9.84375 4.87793 9.84375H10.5469V9.32563C10.5469 8.73853 11.257 8.44409 11.6723 8.85938L13.5967 10.7837C13.8542 11.0413 13.8542 11.4587 13.5967 11.7158L11.6723 13.6402C11.257 14.0555 10.5469 13.7615 10.5469 13.1739V12.6558H4.87793C4.51406 12.6558 4.21875 12.3605 4.21875 11.9966V11.9971ZM18.2812 17.6221C18.2812 17.9859 17.9859 18.2812 17.6221 18.2812H6.9873C6.62344 18.2812 6.32812 17.9859 6.32812 17.6221V14.7217C6.32812 14.3578 6.62344 14.0625 6.9873 14.0625H8.48145C8.84531 14.0625 9.14062 14.3578 9.14062 14.7217V15.4688H15.4688V7.03125H9.14062V7.77832C9.14062 8.14219 8.84531 8.4375 8.48145 8.4375H6.9873C6.62344 8.4375 6.32812 8.14219 6.32812 7.77832V4.87793C6.32812 4.51406 6.62344 4.21875 6.9873 4.21875H17.6221C17.9859 4.21875 18.2812 4.51406 18.2812 4.87793V17.6221Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_188_5336">
				<rect width="22.5" height="22.5" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
