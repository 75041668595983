<template>
	<svg
		width="23"
		height="23"
		viewBox="0 0 23 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_188_5323)">
			<path
				d="M21.33 8.90995L19.89 8.63995C19.71 8.09995 19.53 7.55995 19.17 7.01995L20.07 5.84995C20.34 5.48995 20.25 5.03995 19.98 4.67995L17.91 2.60995C17.64 2.33995 17.1 2.24995 16.74 2.51995L15.57 3.32995C15.03 3.05995 14.4 2.78995 13.77 2.60995L13.5 1.16995C13.41 0.719951 13.05 0.449951 12.6 0.449951H9.62995C9.17995 0.449951 8.81995 0.809951 8.72995 1.16995L8.54995 2.60995C8.00995 2.69995 7.37995 2.96995 6.83995 3.32995L5.75995 2.51995C5.39995 2.24995 4.94995 2.24995 4.58995 2.60995L2.51995 4.67995C2.24995 4.94995 2.15995 5.48995 2.42995 5.84995L3.23995 6.92995C2.87995 7.46995 2.69995 8.09995 2.51995 8.72995L1.16995 8.90995C0.719951 8.99995 0.449951 9.35995 0.449951 9.80995V12.7799C0.449951 13.2299 0.809951 13.5899 1.16995 13.6799L2.51995 13.8599C2.69995 14.4899 2.96995 15.0299 3.23995 15.6599L2.51995 16.74C2.24995 17.0999 2.24995 17.5499 2.60995 17.9099L4.67995 19.98C4.94995 20.25 5.48995 20.3399 5.84995 20.0699L6.92995 19.2599C7.46995 19.5299 8.00995 19.8 8.54995 19.98L8.72995 21.3299C8.81995 21.7799 9.17995 22.0499 9.62995 22.0499H12.6C13.05 22.0499 13.41 21.6899 13.5 21.3299L13.68 19.98C14.31 19.8 14.85 19.5299 15.39 19.2599L16.56 20.0699C16.92 20.3399 17.46 20.25 17.73 19.98L19.8 17.9099C20.07 17.6399 20.16 17.0999 19.89 16.74L19.08 15.5699C19.35 15.0299 19.62 14.4899 19.8 13.9499L21.24 13.6799C21.69 13.5899 21.96 13.2299 21.96 12.7799V9.80995C22.05 9.35995 21.69 8.99995 21.33 8.90995ZM16.47 15.48C15.66 14.49 14.67 13.77 13.41 13.32C13.23 13.23 13.05 13.32 12.96 13.32C12.42 13.5 11.88 13.6799 11.34 13.6799C10.8 13.6799 10.17 13.59 9.71995 13.32C9.53995 13.23 9.35995 13.23 9.26995 13.32C8.00995 13.77 6.92995 14.49 6.20995 15.48C5.30995 14.31 4.76995 12.87 4.76995 11.34C4.76995 7.64995 7.73995 4.58995 11.52 4.58995C15.21 4.58995 18.18 7.55995 18.18 11.34C17.91 12.87 17.37 14.31 16.47 15.48Z"
				fill="white"
			/>
			<path
				d="M11.25 6.8396C9.72005 6.8396 8.55005 8.0996 8.55005 9.5396C8.55005 11.0696 9.72005 12.2396 11.25 12.2396C12.69 12.2396 13.95 11.0696 13.95 9.5396C13.95 8.0096 12.78 6.8396 11.25 6.8396Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_188_5323">
				<rect width="22.5" height="22.5" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
