<template>
	<div class="container">
		<slot></slot>
	</div>
</template>

<style scoped lang="scss">
	.container {
		max-width: 1232px;
		padding: 0px 16px;
		margin: 0 auto;
		width: 100%;
	}
</style>
