<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10 13.75C9.8402 13.75 9.6802 13.6889 9.55816 13.5669L3.30816 7.31688C3.06395 7.07266 3.06395 6.6772 3.30816 6.43313C3.55238 6.18907 3.94785 6.18891 4.19191 6.43313L10 12.2413L15.8082 6.43313C16.0524 6.18891 16.4479 6.18891 16.6919 6.43313C16.936 6.67735 16.9361 7.07282 16.6919 7.31688L10.4419 13.5669C10.3199 13.6889 10.1599 13.75 10 13.75Z"
			fill="white"
		/>
	</svg>
</template>
