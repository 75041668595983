<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10 1.66666C7.69881 1.66666 5.83333 3.53214 5.83333 5.83332C5.83333 8.13451 7.69881 9.99999 10 9.99999C12.3012 9.99999 14.1667 8.13451 14.1667 5.83332C14.1667 3.53214 12.3012 1.66666 10 1.66666Z"
			fill="white"
		/>
		<path
			d="M6.66667 11.6667C4.36548 11.6667 2.5 13.5321 2.5 15.8333V16.6667C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6667V15.8333C17.5 13.5321 15.6345 11.6667 13.3333 11.6667H6.66667Z"
			fill="white"
		/>
	</svg>
</template>
