<template>
	<svg
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.64027 1.25084C-0.652336 6.1699 -2.86625 17.8376 4.04381 25.4736C9.50515 31.5088 20.5163 31.5088 25.9776 25.4736C28.0829 23.148 30 19.2736 30 17.3477C30 15.996 29.5308 15.8048 26.2152 15.8048C19.3771 15.8048 15.8396 19.1411 15.6837 25.7393L15.6065 28.9755L14.6472 24.5652C13.3364 18.5413 11.2192 16.5228 5.53449 15.8771L1.14563 15.3788L4.6104 14.8671C11.8307 13.7991 14.0432 11.4525 14.8039 4.05431L15.2206 0L13.0546 0.0301044C11.863 0.0466619 9.87689 0.596068 8.64027 1.25084ZM15.7714 3.95121C15.7909 10.9061 18.7116 14.0926 25.7348 14.8204C29.9738 15.2599 30 15.2501 30 13.2384C30 10.8812 28.2552 7.14754 25.9776 4.63081C24.0335 2.48211 19.3763 0 17.2891 0C15.943 0 15.7617 0.472639 15.7714 3.95121Z"
			fill="#1347FF"
		/>
	</svg>
</template>
