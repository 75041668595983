<template>
	<svg
		width="23"
		height="23"
		viewBox="0 0 23 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.75 0H3.75002C1.67991 0.00239047 0.00239047 1.67991 0 3.75002V18.75C0.00239047 20.8201 1.67991 22.4976 3.75002 22.5H18.75C20.8201 22.4976 22.4976 20.8201 22.5 18.75V3.75002C22.4976 1.67991 20.8201 0.00239047 18.75 0ZM10.9754 13.7878L7.22535 17.5379C6.85939 17.9039 6.26593 17.9041 5.89986 17.5381C5.89978 17.538 5.89971 17.5379 5.89964 17.5379L4.02463 15.6629C3.66319 15.2921 3.67069 14.6986 4.04143 14.3371C4.40552 13.9822 4.98626 13.9822 5.35034 14.3371L6.5625 15.5493L9.64962 12.4621C10.0204 12.1007 10.6139 12.1082 10.9753 12.4789C11.3303 12.8431 11.3303 13.4238 10.9754 13.7878ZM10.9754 6.28785L7.22535 10.0379C6.85939 10.4039 6.26593 10.4041 5.89986 10.0381C5.89978 10.038 5.89971 10.0379 5.89964 10.0379L4.02463 8.16286C3.65392 7.80142 3.64638 7.20785 4.00782 6.83714C4.36926 6.46643 4.96283 6.4589 5.33354 6.82033C5.3392 6.82585 5.34479 6.83144 5.35034 6.83714L6.5625 8.04933L9.64962 4.96213C10.0111 4.59139 10.6046 4.58389 10.9753 4.94533C11.3461 5.30676 11.3536 5.9003 10.9921 6.27104C10.9866 6.27674 10.981 6.28233 10.9754 6.28785ZM17.8125 16.875H13.125C12.6072 16.875 12.1875 16.4553 12.1875 15.9375C12.1875 15.4197 12.6072 15 13.125 15H17.8125C18.3303 15 18.75 15.4197 18.75 15.9375C18.75 16.4553 18.3303 16.875 17.8125 16.875ZM17.8125 9.37501H13.125C12.6072 9.37501 12.1875 8.95528 12.1875 8.4375C12.1875 7.91973 12.6072 7.5 13.125 7.5H17.8125C18.3303 7.5 18.75 7.91973 18.75 8.4375C18.75 8.95528 18.3303 9.37501 17.8125 9.37501Z"
			fill="white"
		/>
	</svg>
</template>
