export const periods = [
    {
        name: '1 месяц',
        value: '1'
    },
    {
        name: '3 месяца',
        value: '3'
    },
    {
        name: '6 месяцев',
        value: '6'
    },
    {
        name: '12 месяцев',
        value: '12'
    }
]